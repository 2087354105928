import { HStack, Text, Pressable, Box } from '@gluestack-ui/themed-native-base';
import { useState } from 'react';
import { Animated, TouchableOpacity, Platform } from 'react-native';
import Swipeable from 'react-native-gesture-handler/Swipeable';
import { useDeleteCurrentUserBookmarksByIdMutation } from '~/api/uFeedApi';
import { ChatSearchResultItem } from '~/components/ChatSearchResultItem';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import BookmarkRemove from '~/icons/BookmarkRemove';
import type { StreamMessage } from 'stream-chat-react';

type Props = {
  message?: StreamMessage;
  bookMarkID?: number;
  handleMessagePress?: (message?: StreamMessage) => void;
  hideRemoveButton?: boolean;
};

export const BookmarkItem: React.FC<Props> = ({ message, handleMessagePress, bookMarkID, hideRemoveButton }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [deleteBookMark] = useDeleteCurrentUserBookmarksByIdMutation();
  const onDeleteBookMark = () => {
    if (bookMarkID) {
      deleteBookMark({
        id: bookMarkID,
      });
    }
  };
  const RightAction = (_: any, drag: Animated.AnimatedInterpolation<string | number>) => {
    const trans = Animated.add(drag, 65);
    return (
      <TouchableOpacity
        style={{
          justifyContent: 'center',
          transform: [{ translateX: trans }],
        }}
        onPress={onDeleteBookMark}
      >
        <HStack backgroundColor="negative" paddingHorizontal="lg" height="full" alignItems="center">
          <Text style={{ color: 'white' }}>削除</Text>
        </HStack>
      </TouchableOpacity>
    );
  };
  return Platform.OS === 'web' ? (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ position: 'relative' }}
      bg={isHovered && 'selectedSurface'}
    >
      <ChatSearchResultItem message={message} handleMessagePress={handleMessagePress} isBookmark />
      {!hideRemoveButton && isHovered && (
        <Pressable
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
          }}
          py="2xs"
          px="xs"
          borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
          borderRadius={gluestackUIConfig.tokens.borderRadius.medium}
          borderColor="outline"
          backgroundColor="surfaceLow"
          onPress={onDeleteBookMark}
        >
          <HStack alignItems="center" space="xs">
            <BookmarkRemove />
            <Text color="onSurface">ブックマークから削除</Text>
          </HStack>
        </Pressable>
      )}
    </Box>
  ) : (
    <Swipeable renderRightActions={(prog, drag) => RightAction(prog, drag)}>
      <ChatSearchResultItem message={message} handleMessagePress={handleMessagePress} isBookmark />
    </Swipeable>
  );
};
