import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { U_FEED_URL_BASE } from '@env';
import { Ionicons } from '@expo/vector-icons';
import { Box, HStack, Text, Icon, VStack, Button } from '@gluestack-ui/themed-native-base';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useState, useMemo, useCallback, memo, useContext } from 'react';
import { Platform } from 'react-native';

import { GraphAlertBox } from '../GraphAlertBox';

import { useGetFarmsByFarmIdUmCattleGroupsQuery, useGetGroupsByGroupIdMilkingsStatisticQuery } from '~/api/uFeedApi';
import { Skeleton } from '~/components/Skeleton';
import { useGroupMilkAmountByRange } from '~/hooks/useGroupMilkAmountByRange';
import { ChatContext } from '~/lib/StreamChatReact';
import { DateUtil } from '~/utils/DateUtils';

import { MilkAmountPanel } from './MilkAmountPanel';
import type { StyledProps } from 'native-base';
import type { FC } from 'react';

interface Props {
  endDate: Date;
  range: number;
  chatChannelId?: string;
  groupId?: number;
  farmId?: number;
  queryParameters?: {
    endDate: string;
    range: number;
    mode: 'table' | 'graph';
  };
  isPreview?: boolean;
  width?: number;
  height?: number;
  barWidth?: number;
  onTypeChange?: (type: string) => void;
}

function chunk<T extends any[]>(arr: T, size: number) {
  if (!arr) {
    return [];
  }
  return arr.reduce((newarr, _, i) => (i % size ? newarr : [...newarr, arr.slice(i, i + size)]), [] as T[][]);
}

export const MilkAmountDashboard: FC<Props & StyledProps> = memo(
  ({
    endDate,
    range,
    chatChannelId,
    groupId,
    farmId,
    queryParameters,
    isPreview = false,
    width = 400,
    height = 400,
    barWidth = 8,
    onTypeChange,
    ...props
  }) => {
    const navigation = useNavigation();
    const linkTo = useLinkTo();

    const chatContext = useContext(ChatContext);
    const chatClient = chatContext?.client;

    const { milkingsByGroup, milkingsByGroupMA } = useGroupMilkAmountByRange(groupId || 0, endDate, range);
    const milkingsStatisticQuery = useGetGroupsByGroupIdMilkingsStatisticQuery(
      groupId
        ? {
            groupId,
          }
        : skipToken
    );
    const [cowGroupNames, setCowGroupNames] = useState<Record<number, string | undefined>>({});
    const cowGroupsQuery = useGetFarmsByFarmIdUmCattleGroupsQuery({
      farmId: farmId ?? 0,
    });

    useDidUpdate(
      () => {
        setCowGroupNames(
          cowGroupsQuery.data?.reduce((acc, cur) => {
            return {
              ...acc,
              [(cur.id as number).toString()]: cur.name,
            };
          }, {}) ?? {}
        );
      },
      [cowGroupsQuery],
      true
    );

    const data = useMemo(
      () =>
        milkingsByGroup.data?.map((byGroup) => ({
          label: cowGroupNames ? cowGroupNames[byGroup.um_cattle_group_id as number] : '',
          amount: byGroup.data?.slice(-1)[0].amount,
          um_cattle_group_id: byGroup?.um_cattle_group_id,
        })),
      [milkingsByGroup, cowGroupNames]
    );

    const dataMA = useMemo(
      () =>
        milkingsByGroupMA.data?.reduce(
          (acc, byGroup) => ({
            ...acc,
            [byGroup.um_cattle_group_id as number]: {
              amount: byGroup.data?.slice(-1)[0].amount,
            },
          }),
          {}
        ),
      [milkingsByGroupMA]
    );

    const onPressShare = useCallback(async () => {
      const endDateParams = DateUtil.toYYYYMMDD(endDate).split('/');
      const message = `${U_FEED_URL_BASE}/farms/${farmId}/groups/${groupId}/dashboards/milk_amount/${endDateParams[0]}/${endDateParams[1]}/${endDateParams[2]}?target=all`;
      const channel = chatClient?.channel('team', chatChannelId);
      if (Platform.OS === 'web') {
        linkTo(`/chat/${channel?.id}?message=${encodeURIComponent(message)}`);
      } else {
        // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        navigation.navigate('ChatChannelMessages', {
          channelId: channel?.id,
          message,
        });
      }
    }, [chatChannelId, chatClient, endDate, farmId, groupId, linkTo, navigation]);

    if (milkingsByGroup?.isLoading || milkingsByGroupMA?.isLoading || milkingsStatisticQuery?.isLoading) {
      return <Skeleton height={height} padding={4} />;
    }

    if (milkingsByGroup?.isError || milkingsByGroupMA?.isError) {
      return (
        <GraphAlertBox
          onPress={() => {
            milkingsByGroup.refetch();
            milkingsByGroupMA.refetch();
          }}
        />
      );
    }

    return (
      <Box paddingX="auto" paddingBottom={4} {...props}>
        <VStack>
          {/* @ts-expect-error TS(2345): Argument of type '{ label: any; amount: number | u... Remove this comment to see the full error message */}
          {chunk(data, 2)?.map((outerChunks: any) => (
            <HStack key={`outer-milk-chunk-${outerChunks?.[0]?.um_cattle_group_id}`} flexWrap="wrap">
              {chunk(outerChunks, 2)?.map((innerChunks: any, index: any) => (
                <HStack key={`inner-mink-chunk-${innerChunks?.[0]?.um_cattle_group_id ?? index}`} flex={1} m="xs">
                  {innerChunks.map((d: any, innerIndex: any) => (
                    <MilkAmountPanel
                      key={(d.um_cattle_group_id ?? index.toString()) + innerIndex.toString()}
                      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                      label={cowGroupNames ? cowGroupNames[d.um_cattle_group_id] : ''}
                      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                      targetAmount={dataMA[d.um_cattle_group_id]?.amount}
                      amount={d.amount}
                      isPreview={isPreview}
                    />
                  ))}
                  {innerChunks.length === 1 && <Box flex={1} px={3} />}
                </HStack>
              ))}
            </HStack>
          ))}
        </VStack>
        {!isPreview ? (
          <VStack space={2} marginTop={2}>
            <HStack space={1}>
              <Text bold color="onSurface" mx="xs">
                最終更新
              </Text>
              <Text color="onSurface">{milkingsStatisticQuery?.data?.updated_at}</Text>
            </HStack>
          </VStack>
        ) : null}
        {!isPreview ? (
          <HStack mt={2} justifyContent="flex-end">
            <Button
              variant="unstyled"
              onPress={onPressShare}
              leftIcon={<Icon as={Ionicons} name="share-outline" size="md" color="onSurface" />}
              testID="milkamount-dashboard-share-button"
              _text={{ fontWeight: 'bold', color: 'onSurface' }}
            >
              チャットで共有
            </Button>
          </HStack>
        ) : null}
      </Box>
    );
  }
);
