import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useNavigationState } from '@react-navigation/native';
import { useState } from 'react';
import { Platform } from 'react-native';

import {
  useGetCurrentUserSettingQuery,
  useGetNotificationsQuery,
  usePutCurrentUserSettingMutation,
} from '~/api/uFeedApi';

export const useNotificationBadge = () => {
  const userSetting = useGetCurrentUserSettingQuery();
  const notifications = useGetNotificationsQuery({});
  const [put] = usePutCurrentUserSettingMutation();
  const [shouldShowNotificationBadge, setShouldShowNotificationBadge] = useState<boolean>(false);
  const [routeKey, setRootkey] = useState<string | undefined>(undefined);
  const state = useNavigationState((state) => state);

  useDidUpdate(() => {
    if (Platform.OS === 'web') {
      if (routeKey?.startsWith('NotificationsNavigator')) {
        setShouldShowNotificationBadge(false);
      }
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      setRootkey(state?.history?.at(-1)?.key);
    } else {
      if (routeKey?.startsWith('NotificationTab')) {
        setShouldShowNotificationBadge(false);
      }
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      setRootkey(state.routes[0]?.state?.history?.at(-1)?.key);
    }
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
  }, [state?.history?.at(-1)?.key, state.routes[0]?.state?.history?.at(-1)?.key]);

  useDidUpdate(() => {
    const lastNotificationsAt = new Date(notifications?.data?.[0]?.source_evented_at).getTime();

    if (!userSetting?.data?.details?.notificationsTabLastOpenAt || !lastNotificationsAt) {
      setShouldShowNotificationBadge(false);
      return;
    }
    setShouldShowNotificationBadge(lastNotificationsAt > userSetting?.data?.details?.notificationsTabLastOpenAt);
  }, [userSetting, notifications]);

  const openNotificationsPopover = () => {
    if (!shouldShowNotificationBadge) return;
    if (userSetting.isSuccess) {
      put({
        body: {
          user_setting: {
            details: {
              ...userSetting?.data?.details,
              notificationsTabLastOpenAt: new Date().getTime(),
            },
          },
        },
      })
        .unwrap()
        .catch((error) => {
          console.error('update error: ', error);
        });
    }
  };

  return { shouldShowNotificationBadge, openNotificationsPopover };
};
