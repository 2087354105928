import { useNavigation, useRoute } from '@react-navigation/native';
import { useActor } from '@xstate/react';
import { Text, Center, Button, VStack, Link, Alert } from 'native-base';
import React, { useCallback, useState } from 'react';
import { ScreenWidthModal } from '~/components/ScreenWithModal';
import type { CowEvent } from '~/packages/u-motion-api/api/cowEventAutoGeneratedApi';
import { RegisterToUmotion } from './components/RegisterToUmotion';
import { umotionEventRegisterCarcassMachine } from './UmotionEventRegisterCarcassScreen.machine';
import type { RouteProp } from '@react-navigation/native';

type UmotionEventRegisterCarcassScreenParams = {
  UmotionEventRegisterCarcassScreen: {
    cowEvent: CowEvent;
  };
};

type RootStackParamList = {
  UmotionEventRegisterCarcassScreen: UmotionEventRegisterCarcassScreenParams['UmotionEventRegisterCarcassScreen'];
};

export const UmotionEventRegisterCarcassScreen: React.FC = () => {
  const navigation = useNavigation();
  const route = useRoute<RouteProp<RootStackParamList, 'UmotionEventRegisterCarcassScreen'>>();
  const { cowEvent } = route.params;
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [cowUrl, setCowUrl] = useState<string | undefined>(undefined);
  const [state, send] = useActor(umotionEventRegisterCarcassMachine.provide({}), {
    input: { cowEvent },
  });

  const handleCancel = useCallback(() => {
    send({ type: 'CANCEL' });
    navigation.goBack();
  }, [navigation, send]);

  const handleError = useCallback(
    // @ts-expect-error
    (error) => {
      setErrorMessage(`${error?.data?.messages?.[0].field}: ${error?.data?.messages?.[0].message}`);
      send({ type: 'REGISTER_ERROR' });
    },
    [send]
  );

  const handleSuccess = useCallback(
    (cowId: number | undefined) => {
      setCowUrl(`https://www.sand.u-motion.co.jp/#/cowDetail/${cowId}`);
      send({ type: 'REGISTER_SUCCESS' });
    },
    [send]
  );

  return (
    <ScreenWidthModal title="枝肉成績">
      {state.matches('registeringCow') && (
        <RegisterToUmotion
          cowEvent={state.context.cowEvent}
          onCancel={handleCancel}
          onError={handleError}
          onSuccess={handleSuccess}
        />
      )}
      {state.matches('registered') && (
        <Center flex={1}>
          <VStack space={4} justifyContent="center">
            <Alert status="success" colorScheme="success">
              <Alert.Icon />
              <Text bold>枝肉成績を登録しました</Text>
            </Alert>
            <Link href={cowUrl} isExternal>
              U-motionで開く
            </Link>
            <Button variant="ghost" onPress={handleCancel}>
              閉じる
            </Button>
          </VStack>
        </Center>
      )}
      {state.matches('registerError') && (
        <Center flex={1}>
          <VStack space={4}>
            <Alert status="error" colorScheme="error">
              <Alert.Icon />
              <Text>登録に失敗しました</Text>
            </Alert>
            <Text color="error" bold>
              {errorMessage}
            </Text>
            <Button variant="ghost" onPress={handleCancel}>
              閉じる
            </Button>
          </VStack>
        </Center>
      )}
    </ScreenWidthModal>
  );
};
