import { useDidMount } from '@better-typed/react-lifecycle-hooks';
import { uFeedApi } from '~/api/uFeedApi';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import { dispatch } from '~/store';

export const NewMessageHandler: React.FC = () => {
  const { chatClient } = useStreamChatAuthContext();
  useDidMount(() => {
    const messageNewEventListener = chatClient.on('chumly-notification-new', async () => {
      dispatch(uFeedApi.util.invalidateTags(['Notification']));
    });

    return () => {
      messageNewEventListener.unsubscribe();
    };
  });
  return null;
};
