import React from 'react';

const ArrowDownIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7" fill="none">
      <path
        d="M5.61458 6.34115C5.50347 6.34115 5.39931 6.32378 5.30208 6.28906C5.20486 6.25434 5.11458 6.19531 5.03125 6.11198L1.19792 2.27865C1.04514 2.12587 0.96875 1.93142 0.96875 1.69531C0.96875 1.4592 1.04514 1.26476 1.19792 1.11198C1.35069 0.959201 1.54514 0.882812 1.78125 0.882812C2.01736 0.882812 2.21181 0.959201 2.36458 1.11198L5.61458 4.36198L8.86458 1.11198C9.01736 0.959201 9.21181 0.882812 9.44792 0.882812C9.68403 0.882812 9.87847 0.959201 10.0312 1.11198C10.184 1.26476 10.2604 1.4592 10.2604 1.69531C10.2604 1.93142 10.184 2.12587 10.0312 2.27865L6.19792 6.11198C6.11458 6.19531 6.02431 6.25434 5.92708 6.28906C5.82986 6.32378 5.72569 6.34115 5.61458 6.34115Z"
        fill="#BFBFBF"
      />
    </svg>
  );
};

export default ArrowDownIcon;
