import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { HStack, Text } from '@gluestack-ui/themed-native-base';
import { useCallback, useContext, useState } from 'react';
import { useChannelActionContext, useMessageContext } from 'stream-chat-react';
import { ChatContext } from '~/lib/StreamChatReact';
import type { StreamMessage } from 'stream-chat-react';
import type { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

export const MessageParentThreadsLink: React.FC = () => {
  const chatContext = useContext(ChatContext);
  const chatClient = chatContext?.client;
  const actions = useChannelActionContext();
  const openThread = actions.openThread;

  const { message, isMyMessage, threadList } = useMessageContext();
  const [parentMessage, setParentMessage] = useState<StreamMessage<DefaultStreamChatGenerics> | undefined>(undefined);

  const handleOpenThread = useCallback(() => parentMessage !== undefined && openThread(parentMessage), [parentMessage]);

  useDidUpdate(
    () => {
      const threadMessage = async () => {
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        const response = await chatClient.getMessage(message?.parent_id);
        if (!response?.message) {
          return;
        }
        setParentMessage(response?.message);
      };
      if (message?.parent_id) {
        threadMessage();
      }
    },
    [message, chatClient],
    true
  );

  return (
    parentMessage &&
    !threadList && (
      <HStack
        marginY={0}
        marginRight={isMyMessage() ? 0 : 'auto'}
        marginLeft={isMyMessage() ? 'auto' : 0}
        width="fit-content"
      >
        <Text color="onSurface" fontSize="sm" fontWeight="regular">
          このスレッドに返信：
        </Text>
        <Text
          isTruncated
          maxWidth="300"
          fontSize="sm"
          fontWeight="bold"
          color="primary"
          textOverflow="ellipsis"
          onPress={handleOpenThread}
        >
          {parentMessage?.text}
        </Text>
      </HStack>
    )
  );
};
