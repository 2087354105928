import { VStack, Alert, Button, Text, Box } from '@gluestack-ui/themed-native-base';
import { useCallback, useState, memo, useMemo } from 'react';
import { Dimensions } from 'react-native';
import { TabBar, TabView } from 'react-native-tab-view';
import { ChatChannelListWeb } from '~/components/ChatChannelListWeb';

import { gluestackUIConfig } from '~/config/gluestack-ui.config';

import { setEnableWebNotification } from '~/slices/settingsSlice';
import { useAppDispatch } from '~/store';
import { NotificationList } from './NotificationList';
import type { Route } from 'react-native-tab-view';

const initialLayout = {
  width: Dimensions.get('window').width,
};

type Selected = { type: 'my' | 'dm'; channelId?: string; messageId?: string };

const TAB_ENUM = {
  all: 'すべて',
  mention: 'メンション',
  reaction: 'リアクション',
  thread: 'スレッド',
} as const;

type Tab = (typeof TAB_ENUM)[keyof typeof TAB_ENUM];

const SideMenu: React.FC<{ setSelected: React.Dispatch<React.SetStateAction<Selected>> }> = memo(({ setSelected }) => {
  const [index, setIndex] = useState(0);

  const routes = useMemo<(Route & { count: number })[]>(
    () => [
      {
        key: 'all',
        title: 'すべて',
        count: 0,
      },
      {
        key: 'mention',
        title: 'メンション',
        count: 0,
      },
      {
        key: 'reaction',
        title: 'リアクション',
        count: 0,
      },
      {
        key: 'thread',
        title: 'スレッド',
        count: 0,
      },
    ],
    []
  );

  const handleMessagePress = useCallback(
    (message: { type: 'my' | 'dm'; channelId: string; messageId: string }) => {
      setSelected({
        type: message.type,
        channelId: message.channelId,
        messageId: message.messageId,
      });
    },
    [setSelected]
  );

  const dispatch = useAppDispatch();
  const onWebPushConfigPress = useCallback(async () => {
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      await dispatch(setEnableWebNotification(true));
      location.reload();
    }
  }, []);

  const renderTabBar = useCallback((props: any) => {
    return (
      <TabBar<Route & { count: number }>
        {...props}
        index={index}
        setIndex={setIndex}
        indicatorStyle={{
          backgroundColor: gluestackUIConfig.tokens.colors.primary,
          marginHorizontal: gluestackUIConfig.tokens.space.sm,
        }}
        style={{ backgroundColor: gluestackUIConfig.tokens.colors.surfaceLow }}
        renderLabel={({ route, focused }) => (
          <Text
            color={
              focused ? gluestackUIConfig.tokens.colors.onSurface : gluestackUIConfig.tokens.colors.onSurfaceHighest
            }
            size="md"
            fontWeight="bold"
          >
            {route.title}
          </Text>
        )}
      />
    );
  }, []);

  const renderScene = useCallback(
    ({ route }: { route: Route }) => {
      switch (route.key) {
        case 'all':
          return <NotificationList messagePressFn={handleMessagePress} />;
        case 'mention':
          return <NotificationList messagePressFn={handleMessagePress} notificationType="mention" />;
        case 'reaction':
          return <NotificationList messagePressFn={handleMessagePress} notificationType="reaction" />;
        case 'thread':
          return <NotificationList messagePressFn={handleMessagePress} notificationType="thread" />;
        default:
          return null;
      }
    },
    [handleMessagePress]
  );

  return (
    <Box
      width={450}
      height="100%"
      borderColor="surfaceHighest"
      borderRightWidth={gluestackUIConfig.tokens.borderWidths.thick}
    >
      {Notification?.permission === 'default' && (
        <VStack backgroundColor="surfaceLow" padding="xs" borderBottomWidth="medium" borderBottomColor="outline">
          <Alert status="info" padding="xs">
            <VStack space="xs">
              <VStack>
                <Text lineHeight="md" color="onSurface">
                  ウェブ・プッシュ通知を許可すると、メッセージを受信したときに通知バナーを表示できます。{'\n'}
                  開始する場合は「通知を設定する」をクリックして、ブラウザの通知を「許可」してください。
                </Text>
              </VStack>
              <Button onPress={onWebPushConfigPress} backgroundColor="primary" _text={{ color: 'onPrimary' }}>
                通知を設定する
              </Button>
            </VStack>
          </Alert>
        </VStack>
      )}
      <TabView
        navigationState={{
          index,
          routes,
        }}
        onIndexChange={setIndex}
        renderScene={renderScene}
        renderTabBar={renderTabBar}
        initialLayout={initialLayout}
        lazy
      />
    </Box>
  );
});

export const NotificationsScreen = () => {
  const [selected, setSelected] = useState<Selected>({
    type: 'my',
  });

  return (
    <>
      {selected.channelId ? (
        <ChatChannelListWeb
          type={selected?.type}
          channelId={selected?.channelId}
          messageId={selected?.messageId}
          sideMenu={<SideMenu setSelected={setSelected} />}
          hideChannelList
        />
      ) : (
        <SideMenu setSelected={setSelected} />
      )}
    </>
  );
};
