import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useIsFocused } from '@react-navigation/native';
import React, { useCallback, useContext, useRef } from 'react';

import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { useChannelActionContext, ChatContext } from '~/lib/StreamChatReact';
import type { StreamChatGenerics } from '~/types';
import type { MessageResponse } from 'stream-chat';

type Props = {
  messageId?: string;
  channelId?: string;
  channelType?: 'team' | 'messaging';
};

export const ChannelSwitcher: React.FC<Props> = ({ messageId, channelId, channelType = 'team' }) => {
  const { appChannel, setAppChannel } = useStreamChatContext();
  const chatContext = useContext(ChatContext);
  const chatClient = chatContext?.client;
  const actions = useChannelActionContext();
  const { jumpToMessage, openThread } = actions;
  const isFocused = useIsFocused();
  const targetMessage = useRef<MessageResponse<StreamChatGenerics>>();

  const switchChannel = useCallback((channelId: string, channelType: 'team' | 'messaging') => {
    const newChannel = chatClient?.channel(channelType, channelId);
    setAppChannel(newChannel);
  }, []);

  const getMessage = useCallback(async (messageId: string) => {
    const messageObj = await chatClient?.getMessage(messageId);
    const message = messageObj?.message;
    return message;
  }, []);

  const handleParentMessage = useCallback(
    async (parentMessageId: string) => {
      const parent = await getMessage(parentMessageId);
      if (!parent) return;
      openThread(parent);
    },
    [openThread]
  );

  const goToMessage = useCallback(() => {
    if (!targetMessage.current) {
      return;
    }
    if (appChannel?.id !== targetMessage.current.channel?.id) {
      targetMessage.current = undefined;
      return;
    }
    if (targetMessage.current.parent_id) {
      handleParentMessage(targetMessage.current.parent_id);
    } else if (!!targetMessage.current.reply_count && targetMessage.current.reply_count > 0) {
      openThread(targetMessage.current);
    } else {
      jumpToMessage(targetMessage.current.id);
    }
    targetMessage.current = undefined;
  }, [targetMessage, appChannel, jumpToMessage, openThread, handleParentMessage]);

  useDidUpdate(
    () => {
      if (!isFocused) {
        return;
      }
      if (!channelId) {
        return;
      }
      if (!messageId) {
        // messageIdがない場合はchannelを切り替えるのみ
        switchChannel(channelId, channelType);
        return;
      }
      const handleMessage = async () => {
        const message = await getMessage(messageId);
        if (!message || !message.channel?.id || message.channel?.id !== channelId) {
          // 指定されたchannelIdとmessageが所属するchannelIdが一致しない場合はchannelIdを優先
          switchChannel(channelId, channelType);
          return;
        }
        // 対象となるmessageを保持しておきchannelを切り替える
        targetMessage.current = message;
        switchChannel(message.channel.id, channelType);
        goToMessage();
      };
      handleMessage();
    },
    [channelId, channelType, messageId, isFocused],
    true
  );

  return <></>;
};
