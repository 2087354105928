import React from 'react';

const BookmarkRemove = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
      <path
        d="M16 4H10V2H16V4ZM0 18V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H9C8.66667 0.5 8.41667 0.979167 8.25 1.4375C8.08333 1.89583 8 2.41667 8 3C8 4.2 8.37917 5.25833 9.1375 6.175C9.89583 7.09167 10.85 7.66667 12 7.9C12.3833 7.95 12.7167 7.975 13 7.975C13.2833 7.975 13.6167 7.95 14 7.9V18L7 15L0 18Z"
        fill="#0D0D0D"
      />
    </svg>
  );
};

export default BookmarkRemove;
