import { Text, VStack } from '@gluestack-ui/themed-native-base';
import * as React from 'react';
import { useCallback, useState } from 'react';

import { BaseFormBuilder } from '~/components/builder/BaseFormBuilder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { usePostCowEventMutation } from '~/packages/u-motion-api/api/cowEventAutoGeneratedApi';
import { DateUtil } from '~/utils/DateUtils';

const fields = [
  {
    key: 'cowId',
    label: '牛ID',
  },
  {
    key: 'cowUid',
    label: '個体識別番号',
  },
  {
    key: 'cowNo',
    label: '牛番号',
  },
  {
    key: 'grade',
    label: '等級',
  },
  {
    key: 'occurredAt',
    label: '販売日',
    type: 'datePicker',
    rules: {
      required: {
        value: true,
        message: '販売日を入力してください',
      },
    },
  },
  {
    key: 'yieldGrade',
    label: '歩留',
    wrapperStyle: { zIndex: 21 },
    type: 'selectPopover',
    options: [
      { label: 'A', value: 'A' },
      { label: 'B', value: 'B' },
      { label: 'C', value: 'C' },
    ],
    rules: {
      required: {
        value: true,
        message: '歩留を選択してください',
      },
    },
  },
  {
    key: 'meetGrade',
    label: '肉質',
    wrapperStyle: { zIndex: 20 },
    type: 'selectPopover',
    options: [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' },
    ],
    rules: {
      required: {
        value: true,
        message: '歩留を選択してください',
      },
    },
  },
  {
    key: 'beforeSlaughterWeight',
    label: 'と畜前重量',
  },
  {
    key: 'dressedCarcassWeightOfL',
    label: '枝肉重量(左)',
  },
  {
    key: 'dressedCarcassWeightOfR',
    label: '枝肉重量(右)',
  },
  {
    key: 'loinArea',
    label: '胸最長筋面積',
  },
  {
    key: 'ribsThickness',
    label: 'ばらの厚さ',
  },
  {
    key: 'subcutaneousFat',
    label: '皮下脂肪の厚さ',
  },
  {
    key: 'yieldBaseValue',
    label: '歩留基準値',
  },
  {
    key: 'bmsNo',
    label: 'BMS No',
  },
  {
    key: 'marblingGrade',
    label: '脂肪交雑等級',
  },
  {
    key: 'bcsNo',
    label: 'BCS No.',
  },
  {
    key: 'gloss',
    label: '光沢',
  },
  {
    key: 'bcsAndGlossGrade',
    label: 'BCSと光沢の等級',
  },
  {
    key: 'tight',
    label: '締まり',
  },
  {
    key: 'texture',
    label: 'きめ',
  },
  {
    key: 'tightAndTextureGrade',
    label: '締まりときめの等級',
  },
  {
    key: 'bfsNo',
    label: 'BFS No.',
  },
  {
    key: 'fatLuster',
    label: '光沢と質',
  },
  {
    key: 'bfsAndFatLusterGrade',
    label: 'bfsと光沢と質の等級',
  },
  {
    key: 'carcassDefectSummary',
    label: '瑕疵',
  },
  {
    key: 'otherFaultCorrection',
    label: '歩留補正',
  },
  {
    key: 'dressedCarcassUnitPrice',
    label: '枝肉単価',
  },
  {
    key: 'totalDressedCarcassWeight',
    label: '枝肉総重量',
  },
  {
    key: 'dressedCarcassSalesPrice',
    label: '枝肉販売金額',
  },
  {
    key: 'visceralDestruction',
    label: '内臓廃棄',
  },
  {
    key: 'comment',
    label: 'コメント',
  },
];

export type CowEventData = {
  eventName: string;
  eventType: string;
  cowId: number;
  cowNo?: string;
  cowUid?: string;
  grade?: string;
  slaughterNo?: string;
  occurredAt?: string | number;
  yieldGrade?: string;
  meetGrade?: string;
  beforeSlaughterWeight?: number;
  dressedCarcassWeightOfL?: number;
  dressedCarcassWeightOfR?: number;
  loinArea?: number;
  ribsThickness?: number;
  subcutaneousFat?: number;
  yieldBaseValue?: number;
  bmsNo?: string;
  marblingGrade?: string;
  bcsNo?: string;
  gloss?: string;
  bcsAndGlossGrade?: string;
  tight?: string;
  texture?: string;
  tightAndTextureGrade?: string;
  bfsNo?: string;
  fatLuster?: string;
  bfsAndFatLusterGrade?: string;
  carcassDefectSummary?: string;
  otherFaultCorrection?: string;
  dressedCarcassUnitPrice?: number;
  totalDressedCarcassWeight?: number;
  dressedCarcassSalesPrice?: number;
  visceralDestruction?: string;
  comment?: string;
};

type Props = {
  cowEvent: CowEventData;
  onCancel: () => void;
  onError: (error: string) => void;
  onSuccess: (cowId: number | undefined) => void;
};

export const RegisterToUmotion = ({ cowEvent, onCancel, onError, onSuccess }: Props) => {
  const [postCowEvent, postCowEventResult] = usePostCowEventMutation();
  const defaultValues = getDefaultValues(cowEvent);

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const onSubmit = useCallback((cowEvent: CowEventData) => {
    const result = postCowEvent({
      cowEvent: {
        eventName: '枝肉成績',
        eventType: 'carcass',
        cowId: cowEvent.cowId,
        grade: cowEvent.grade,
        slaughterNo: cowEvent.slaughterNo,
        // @ts-expect-error
        occurredAt: Number(DateUtil.convertToValueOf(cowEvent.occurredAt)),
        yieldGrade: cowEvent.yieldGrade,
        meetGrade: cowEvent.meetGrade,
        beforeSlaughterWeight: cowEvent.beforeSlaughterWeight,
        dressedCarcassWeightOfL: cowEvent.dressedCarcassWeightOfL,
        dressedCarcassWeightOfR: cowEvent.dressedCarcassWeightOfR,
        loinArea: cowEvent.loinArea,
        ribsThickness: cowEvent.ribsThickness,
        subcutaneousFat: cowEvent.subcutaneousFat,
        yieldBaseValue: cowEvent.yieldBaseValue,
        bmsNo: cowEvent.bmsNo,
        marblingGrade: cowEvent.marblingGrade,
        bcsNo: cowEvent.bcsNo,
        gloss: cowEvent.gloss,
        bcsAndGlossGrade: cowEvent.bcsAndGlossGrade,
        tight: cowEvent.tight,
        texture: cowEvent.texture,
        tightAndTextureGrade: cowEvent.tightAndTextureGrade,
        bfsNo: cowEvent.bfsNo,
        fatLuster: cowEvent.fatLuster,
        bfsAndFatLusterGrade: cowEvent.bfsAndFatLusterGrade,
        otherFaultCorrection: cowEvent.otherFaultCorrection,
        dressedCarcassUnitPrice: cowEvent.dressedCarcassUnitPrice,
        totalDressedCarcassWeight: cowEvent.totalDressedCarcassWeight,
        dressedCarcassSalesPrice: cowEvent.dressedCarcassSalesPrice,
        visceralDestruction: cowEvent.visceralDestruction,
        comment: cowEvent.comment,
      },
    })
      .unwrap()
      .then((data) => {
        onSuccess?.(data?.id);
      })
      .catch((error) => {
        console.error('postCow error: ', error);
        onError?.(error);
      });
  }, []);

  return (
    <BaseScreenBuilder>
      <VStack space="lg">
        <BaseFormBuilder
          // @ts-expect-error TS(2740): Type '{}' is missing the following properties from... Remove this comment to see the full error message
          queryResult={{}}
          onSubmit={onSubmit}
          onCancel={onCancel}
          defaultValues={defaultValues}
          // @ts-expect-error
          fields={fields}
        />
        {errorMessages
          ? errorMessages.map((errorMessage, i) => (
              <Text fontSize="md" fontWeight="medium" color="red.400" key={i}>
                {errorMessage}
              </Text>
            ))
          : null}
      </VStack>
    </BaseScreenBuilder>
  );
};

const getDefaultValues = (cowEvent: CowEventData) => {
  return {
    cowId: cowEvent?.cowId || '',
    cowNo: cowEvent?.cowNo || '',
    cowUid: cowEvent?.cowUid || '',
    grade: cowEvent?.grade || '',
    slaughterNo: cowEvent?.slaughterNo || '',
    occurredAt: cowEvent?.occurredAt || '',
    yieldGrade: cowEvent?.yieldGrade || '',
    meetGrade: cowEvent?.meetGrade || '',
    beforeSlaughterWeight: cowEvent?.beforeSlaughterWeight || '',
    dressedCarcassWeightOfL: cowEvent?.dressedCarcassWeightOfL || '',
    dressedCarcassWeightOfR: cowEvent?.dressedCarcassWeightOfR || '',
    loinArea: cowEvent?.loinArea || '',
    ribsThickness: cowEvent?.ribsThickness || '',
    subcutaneousFat: cowEvent?.subcutaneousFat || '',
    yieldBaseValue: cowEvent?.yieldBaseValue || '',
    bmsNo: cowEvent?.bmsNo || '',
    marblingGrade: cowEvent?.marblingGrade || '',
    bcsNo: cowEvent?.bcsNo || '',
    gloss: cowEvent?.gloss || '',
    bcsAndGlossGrade: cowEvent?.bcsAndGlossGrade || '',
    tight: cowEvent?.tight || '',
    texture: cowEvent?.texture || '',
    tightAndTextureGrade: cowEvent?.tightAndTextureGrade || '',
    bfsNo: cowEvent?.bfsNo || '',
    fatLuster: cowEvent?.fatLuster || '',
    bfsAndFatLusterGrade: cowEvent?.bfsAndFatLusterGrade || '',
    carcassDefectSummary: cowEvent?.carcassDefectSummary || '',
    otherFaultCorrection: cowEvent?.otherFaultCorrection || '',
    dressedCarcassUnitPrice: cowEvent?.dressedCarcassUnitPrice || '',
    totalDressedCarcassWeight: cowEvent?.totalDressedCarcassWeight || '',
    dressedCarcassSalesPrice: cowEvent?.dressedCarcassSalesPrice || '',
    visceralDestruction: cowEvent?.visceralDestruction || '',
    comment: cowEvent?.comment || '',
  };
};
