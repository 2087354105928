import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { FlatList } from '@gluestack-ui/themed';
import { Center, Divider, Spinner, VStack } from '@gluestack-ui/themed-native-base';
import { useRef } from 'react';
import { type StreamMessage } from 'stream-chat-react';
import type { Bookmark } from '~/api/uFeedApi';
import { useGetCurrentUserBookmarksQuery } from '~/api/uFeedApi';
import { EmptyMessage } from '~/components/EmptyMessage';
import { useBookmarkQuery } from '~/hooks/useBookmarkQuery';
import { BookmarkItem } from './BookmarkItem';

type Props = {
  handleMessagePress?: (message?: StreamMessage) => void;
  selected?: { type: 'my' | 'dm'; channelId?: string; messageId?: string };
  isPopover?: boolean;
};

export const BookmarkList: React.FC<Props> = ({ handleMessagePress, isPopover = false }) => {
  const { messages, initialized, bookmarkQuery } = useBookmarkQuery();
  const currentUserBookmarks = useGetCurrentUserBookmarksQuery();
  const bookmarksRef = useRef<Bookmark[] | null>(null);

  useDidUpdate(
    () => {
      if (currentUserBookmarks.data) {
        bookmarksRef.current = currentUserBookmarks.data;
      }
    },
    [currentUserBookmarks.data],
    true
  );

  return initialized ? (
    <FlatList
      data={messages}
      // @ts-expect-error
      keyExtractor={(item) => `${item.bookmark_created_at}_${item.message.id}`}
      renderItem={({ item }) => {
        /* @ts-expect-error */
        const bookMarkID = bookmarksRef.current?.find((b) => b.message_id === item?.message?.id)?.id;
        return (
          <VStack>
            <BookmarkItem
              // @ts-expect-error
              message={item.message}
              handleMessagePress={handleMessagePress}
              bookMarkID={bookMarkID}
              hideRemoveButton={isPopover}
            />
          </VStack>
        );
      }}
      onEndReached={isPopover ? () => null : bookmarkQuery}
      onEndReachedThreshold={0.3}
      ListEmptyComponent={<EmptyMessage emptyMessage="ブックマークがありませんでした" />}
      backgroundColor="white"
      ItemSeparatorComponent={() => (
        <VStack paddingHorizontal="md">
          <Divider backgroundColor="outline" />
        </VStack>
      )}
    />
  ) : (
    <Center flex={1}>
      <Spinner />
    </Center>
  );
};
