import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import * as React from 'react';
import { useContext } from 'react';
import { ChatChannelListWeb } from '~/components/ChatChannelListWeb';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { useChatContext, ChatContext } from '~/lib/StreamChatReact';
import { setChatBotQuickAction } from '~/slices/chatBotQuickActionSlice';
import { useAppDispatch } from '~/store';

type ChannelListCategory = 'my' | 'pinned' | 'others';

interface Props {
  type: ChannelListCategory;
  disableAddChannel?: boolean;
}

export const ChatBotScreen: React.FC<Props> = React.memo(() => {
  const dispatch = useAppDispatch();
  const { chatUserId, isUserConnected } = useStreamChatContext();
  const chatContext = useContext(ChatContext);
  const chatClient = chatContext?.client;
  const { channel } = useChatContext();
  const [myConciergeChannel, setMyConciergeChannel] = React.useState<string | undefined>(undefined);
  const [messageId, setMessageId] = React.useState('');

  useDidUpdate(
    () => {
      if (!chatUserId || !chatClient || !isUserConnected) return;

      chatClient
        .queryChannels({
          is_concierge_channel: true,
        })
        .then((channels) => {
          setMyConciergeChannel(channels[0]?.id);
        });
    },
    [chatUserId, chatClient, isUserConnected],
    true
  );

  useDidUpdate(() => {
    let listener: { unsubscribe: () => void } = { unsubscribe: () => {} };

    if (channel?.cid) {
      // @ts-ignore
      listener = chatClient?.on('message.new', (event) => {
        if (event.cid !== channel.cid) return;
        setMessageId(event.message?.id || '');
        dispatch(setChatBotQuickAction(false));
      });
    }

    return listener.unsubscribe;
  }, [channel?.cid]);

  if (myConciergeChannel === undefined) return null;

  return (
    <ChatChannelListWeb
      type="concierge"
      channelId={myConciergeChannel}
      hideChannelList
      messageId={messageId}
      setMessageId={setMessageId}
    />
  );
});
