import { useNavigation } from '@react-navigation/native';
import { Box, VStack, HStack, Text, Button } from 'native-base';
import { useState, useCallback, useEffect } from 'react';
import { useFetchFarmCurrentQuery } from '~/packages/u-motion-api/api/farmApi';
import { useUmotionCow } from '~/packages/u-motion-api/hooks/useUmotionCow';
import type { CowEventData } from '~/screens/UmotionEventRegisterCarcassScreen/components/RegisterToUmotion';
import type { StackNavigationProp } from '@react-navigation/stack';

export const PreviewCarcassParams = ({ data }: any) => {
  const [isPosted, setIsPosted] = useState(false);
  const navigation = useNavigation<StackNavigationProp<any>>();
  const farmCurrentQuery = useFetchFarmCurrentQuery();
  const { searchCowByUid } = useUmotionCow();
  const [cow, setCow] = useState<any>();

  if (!data) return null;

  const onRegisterPress = async () => {
    navigation.navigate('UmotionEventRegisterCarcassModal', {
      cowEvent: convertToUmotionCowEventCarcassRegisterData(data, cow),
    });
  };

  const onSwitchFarmPress = async () => {
    navigation.navigate('UmotionSwitchFarmModal');
  };

  useEffect(() => {
    searchCowByUid(data.cowUid)?.then((res) => {
      setCow(res.data?.[0]);
    });
  }, [data]);

  const PedigreeParam = useCallback(({ label, value }: { label: string; value: string }) => {
    return (
      <HStack>
        <Text width="50%" fontWeight="bold">
          {label}
        </Text>
        <Text width="50%">{value}</Text>
      </HStack>
    );
  }, []);

  return (
    <VStack space="md">
      <HStack alignItems="center" justifyContent="space-between" space="md">
        <HStack space="sm">
          <Text bold>牧場:</Text>
          <Text>{farmCurrentQuery?.data?.farmName}</Text>
        </HStack>
        <Button
          onPress={onSwitchFarmPress}
          variant="outline"
          borderRadius="8px"
          backgroundColor="#ffffff"
          colorScheme="orange"
          borderColor="#d9d9d9"
        >
          牧場切替
        </Button>
      </HStack>
      {!isPosted && (
        <Button onPress={onRegisterPress} backgroundColor="#FF9A47" borderRadius="8px">
          U-motionに登録
        </Button>
      )}
      <Box backgroundColor="white" borderRadius={20} padding={4} marginBottom={4}>
        <VStack space={4}>
          <PedigreeParam label="個体識別番号" value={data.cowUid} />
          <PedigreeParam label="生年月日" value={data.birthday} />
          <PedigreeParam label="と畜番号" value={data.slaughterNo} />
          <PedigreeParam label="等級" value={data.grade} />
          <PedigreeParam label="歩留" value={data.yieldGrade} />
          <PedigreeParam label="肉質" value={data.meetGrade} />
          <PedigreeParam label="と畜前重量" value={data.beforeSlaughterWeight} />
          <PedigreeParam label="枝肉重量(左)" value={data.dressedCarcassWeightOfL} />
          <PedigreeParam label="枝肉重量(右)" value={data.dressedCarcassWeightOfR} />
          <PedigreeParam label="胸最長筋面積" value={data.loinArea} />
          <PedigreeParam label="ばらの厚さ" value={data.ribsThickness} />
          <PedigreeParam label="皮下脂肪の厚さ" value={data.subcutaneousFat} />
          <PedigreeParam label="歩留基準値" value={data.yieldBaseValue} />
          <PedigreeParam label="BMS No" value={data.bmsNo} />
          <PedigreeParam label="脂肪交雑等級" value={data.marblingGrade} />
          <PedigreeParam label="BCS No." value={data.bcsNo} />
          <PedigreeParam label="光沢" value={data.gloss} />
          <PedigreeParam label="等級" value={data.bcsAndGlossGrade} />
          <PedigreeParam label="締まり" value={data.tight} />
          <PedigreeParam label="きめ" value={data.texture} />
          <PedigreeParam label="等級" value={data.tightAndTextureGrade} />
          <PedigreeParam label="BFS No." value={data.bfsNo} />
          <PedigreeParam label="光沢と質" value={data.fatLuster} />
          <PedigreeParam label="等級" value={data.bfsAndFatLusterGrade} />
          <PedigreeParam label="瑕疵" value={data.carcassDefectSummary} />
          <PedigreeParam label="歩留補正" value={data.otherFaultCorrection} />
          <PedigreeParam label="枝肉単価" value={data.dressedCarcassUnitPrice} />
          <PedigreeParam label="枝肉総重量" value={data.totalDressedCarcassWeight} />
          <PedigreeParam label="枝肉販売金額" value={data.dressedCarcassSalesPrice} />
          <PedigreeParam label="内臓廃棄" value={data.visceralDestruction} />
          <PedigreeParam label="コメント" value={data.comment} />
        </VStack>
      </Box>
    </VStack>
  );
};

export interface OCRCattleData {
  slaughterNo?: number;
  grade: string;
  birthday: string;
  yieldGrade: string;
  meetGrade: string;
  beforeSlaughterWeight?: number;
  dressedCarcassWeightOfL?: number;
  dressedCarcassWeightOfR?: number;
  loinArea?: number;
  ribsThickness?: number;
  subcutaneousFat?: number;
  yieldBaseValue?: number;
  bmsNo?: number;
  marblingGrade?: number;
  bcsNo?: number;
  gloss?: number;
  bcsAndGlossGrade?: number;
  tight?: number;
  texture?: number;
  tightAndTextureGrade?: number;
  bfsNo?: number;
  fatLuster?: number;
  bfsAndFatLusterGrade?: number;
  fault?: string;
  otherFault?: string;
  otherFaultCorrection: string;
  dressedCarcassUnitPrice?: number;
  totalDressedCarcassWeight?: number;
  dressedCarcassSalesPrice?: number;
  visceralDestruction?: string;
  comment?: string;
}

const convertToUmotionCowEventCarcassRegisterData = (ocrData: OCRCattleData, cow: any): CowEventData => {
  return {
    eventName: '枝肉成績',
    eventType: 'carcass',
    //cowId: 12049,
    cowId: cow?.cowId,
    cowNo: cow?.cowNo,
    cowUid: cow?.cowUid,
    slaughterNo: ocrData.slaughterNo?.toString(),
    //occurredAt: ocrData.occurredAt,
    yieldGrade: ocrData.yieldGrade,
    meetGrade: ocrData.meetGrade,
    beforeSlaughterWeight: ocrData.beforeSlaughterWeight,
    dressedCarcassWeightOfL: ocrData.dressedCarcassWeightOfL,
    dressedCarcassWeightOfR: ocrData.dressedCarcassWeightOfR,
    loinArea: ocrData.loinArea,
    ribsThickness: ocrData.ribsThickness,
    subcutaneousFat: ocrData.subcutaneousFat,
    yieldBaseValue: ocrData.yieldBaseValue,
    bmsNo: ocrData.bmsNo?.toString(),
    marblingGrade: ocrData.marblingGrade?.toString(),
    bcsNo: ocrData.bcsNo?.toString(),
    gloss: ocrData.gloss?.toString(),
    bcsAndGlossGrade: ocrData.bcsAndGlossGrade?.toString(),
    tight: ocrData.tight?.toString(),
    texture: ocrData.texture?.toString(),
    tightAndTextureGrade: ocrData.tightAndTextureGrade?.toString(),
    bfsNo: ocrData.bfsNo?.toString(),
    fatLuster: ocrData.fatLuster?.toString(),
    bfsAndFatLusterGrade: ocrData.bfsAndFatLusterGrade?.toString(),
    //carcassDefectSummary: ocrData.carcassDefectSummary?.toString(),
    otherFaultCorrection: ocrData.otherFaultCorrection,
    dressedCarcassUnitPrice: ocrData.dressedCarcassUnitPrice,
    totalDressedCarcassWeight: ocrData.totalDressedCarcassWeight,
    dressedCarcassSalesPrice: ocrData.dressedCarcassSalesPrice,
    visceralDestruction: ocrData.visceralDestruction,
    comment: ocrData.comment,
    grade: ocrData.grade,
  };
};
