import { setup } from 'xstate';
import type { CowEvent } from '~/packages/u-motion-api/api/cowEventAutoGeneratedApi';

export const umotionEventRegisterCarcassMachine = setup({
  types: {
    context: {} as {
      cowEvent: CowEvent;
    },
    events: {} as { type: 'CANCEL' } | { type: 'REGISTER_SUCCESS' } | { type: 'REGISTER_ERROR' },
    input: {} as { cowEvent: CowEvent },
  },
}).createMachine({
  id: 'umotionCowRegisterScreenMachine',
  initial: 'registeringCow',
  context: ({ input }) => ({
    cowEvent: input.cowEvent,
    dups: [],
    existingCows: [],
  }),
  states: {
    registeringCow: {
      on: {
        REGISTER_SUCCESS: 'registered',
        REGISTER_ERROR: 'registerError',
      },
    },
    registerError: {
      type: 'final',
    },
    registered: {
      type: 'final',
    },
    exit: {
      type: 'final',
    },
  },
});
