import { Dimensions } from 'react-native';

// @ts-expect-error TS(2305): Module '"~/lib/StreamChatReact"' has no exported m... Remove this comment to see the full error message
import type { DeepPartial, Theme } from '~/lib/StreamChatReact';
import { gluestackUIConfig } from './config/gluestack-ui.config';

const windowDimensions = Dimensions.get('window');
export const TEXT_WIDTH = windowDimensions.width * 0.7;
const FONT_SIZE = gluestackUIConfig.tokens.fontSizes.md;
const FONT_FAMILY = 'Hiragino Sans';

export const getChatTheme = (isUDFontEnabled = false) => {
  const fontFamilySettings = isUDFontEnabled
    ? {
        fontFamily: 'BIZUDPGothic_400Regular',
      }
    : {
        fontFamily: 'Hiragino Sans',
      };

  return {
    attachmentPicker: {
      durationText: fontFamilySettings,
      errorButtonText: fontFamilySettings,
      errorText: fontFamilySettings,
    },
    channel: {
      selectChannel: fontFamilySettings,
    },
    channelListHeaderErrorIndicator: {
      errorText: fontFamilySettings,
    },
    channelPreview: {
      date: fontFamilySettings,
      message: fontFamilySettings,
      title: fontFamilySettings,
      unreadTitle: fontFamilySettings,
      container: {
        borderBottomWidth: 0,
      },
    },
    dateHeader: {
      container: {
        backgroundColor: gluestackUIConfig.tokens.colors.surfaceHighest,
        padding: gluestackUIConfig.tokens.space['3xs'],
        borderRadius: gluestackUIConfig.tokens.borderRadius.full,
      },
      text: {
        ...fontFamilySettings,
        color: gluestackUIConfig.tokens.colors.onSurfaceHigh,
        fontSize: gluestackUIConfig.tokens.fontSizes.sm,
        lineHeight: gluestackUIConfig.tokens.space.sm,
      },
    },
    emptyStateIndicator: {
      channelDetails: fontFamilySettings,
      channelTitle: fontFamilySettings,
    },
    iconBadge: {
      unreadCount: fontFamilySettings,
    },
    imageGallery: {
      footer: {
        imageCountText: fontFamilySettings,
      },
      grid: {
        handleText: fontFamilySettings,
      },
      header: {
        dateText: fontFamilySettings,
        usernameText: fontFamilySettings,
      },
      videoControl: {
        durationTextStyle: fontFamilySettings,
      },
    },
    inlineDateSeparator: {
      container: {
        backgroundColor: gluestackUIConfig.tokens.colors.surfaceHighest,
        padding: gluestackUIConfig.tokens.space['3xs'],
        borderRadius: gluestackUIConfig.tokens.borderRadius.full,
      },
      text: {
        ...fontFamilySettings,
        color: gluestackUIConfig.tokens.colors.onSurfaceHigh,
        fontSize: gluestackUIConfig.tokens.fontSizes.sm,
        lineHeight: gluestackUIConfig.tokens.space.sm,
      },
    },
    loadingErrorIndicator: {
      errorText: fontFamilySettings,
      retryText: fontFamilySettings,
    },
    loadingIndicator: {
      loadingText: fontFamilySettings,
    },
    messageInput: {
      cooldownTimer: {
        text: fontFamilySettings,
      },
      editingBoxHeaderTitle: {
        editingBoxHeaderTitle: fontFamilySettings,
      },
      fileUploadPreview: {
        audioAttachment: {
          progressDurationText: fontFamilySettings,
        },
        filenameText: fontFamilySettings,
        fileSizeText: fontFamilySettings,
      },
      giphyCommandInput: {
        giphyText: fontFamilySettings,
      },
      inputBox: {
        ...fontFamilySettings,
        fontSize: FONT_SIZE,
      },
      sendMessageDisallowedIndicator: {
        text: fontFamilySettings,
      },
      showThreadMessageInChannelButton: {
        text: fontFamilySettings,
      },
      suggestions: {
        command: {
          args: fontFamilySettings,
          title: fontFamilySettings,
        },
        emoji: {
          text: fontFamilySettings,
        },
        header: {
          title: fontFamilySettings,
        },
        mention: {
          name: fontFamilySettings,
          tag: fontFamilySettings,
        },
      },
    },
    messageList: {
      container: {
        backgroundColor: 'transparent',
      },
      errorNotificationText: fontFamilySettings,
      inlineUnreadIndicator: {
        text: fontFamilySettings,
      },
      messageSystem: {
        dateText: fontFamilySettings,
        text: fontFamilySettings,
      },
      scrollToBottomButton: {
        unreadCountNotificationText: fontFamilySettings,
      },
    },
    messageSimple: {
      actions: {
        buttonText: fontFamilySettings,
      },
      avatarWrapper: {
        leftAlign: {
          justifyContent: 'flex-start',
        },
      },
      card: {
        authorName: fontFamilySettings,
        authorNameFooter: fontFamilySettings,
        footer: {
          description: fontFamilySettings,
          title: fontFamilySettings,
        },
        playButtonStyle: {
          durationTextStyle: fontFamilySettings,
        },
      },
      container: {
        alignItems: 'flex-start',
      },
      content: {
        container: {
          paddingTop: 0,
        },
        containerInner: {
          backgroundColor: 'transparent',
          maxWidth: undefined,
          borderTopLeftRadius: gluestackUIConfig.tokens.space.none,
          borderBottomLeftRadius: gluestackUIConfig.tokens.space.none,
          borderBottomRightRadius: gluestackUIConfig.tokens.space.none,
          borderWidth: gluestackUIConfig.tokens.space.none,
          flexDirection: 'column-reverse',
        },
        deletedMetaText: fontFamilySettings,
        markdown: {
          autolink: fontFamilySettings,
          blockQuoteText: fontFamilySettings,
          br: fontFamilySettings,
          codeBlock: fontFamilySettings,
          del: fontFamilySettings,
          em: fontFamilySettings,
          heading: fontFamilySettings,
          heading1: fontFamilySettings,
          heading2: fontFamilySettings,
          heading3: fontFamilySettings,
          heading4: fontFamilySettings,
          heading5: fontFamilySettings,
          heading6: fontFamilySettings,
          inlineCode: fontFamilySettings,
          listItemBullet: fontFamilySettings,
          listItemNumber: fontFamilySettings,
          listItemText: fontFamilySettings,
          mailTo: fontFamilySettings,
          mentions: {
            ...fontFamilySettings,
            color: gluestackUIConfig.tokens.colors.primary,
            fontWeight: gluestackUIConfig.tokens.fontWeights.normal,
          },
          newline: fontFamilySettings,
          noMargin: fontFamilySettings,
          paragraph: fontFamilySettings,
          paragraphCenter: fontFamilySettings,
          strong: fontFamilySettings,
          tableHeaderCell: fontFamilySettings,
          text: {
            color: gluestackUIConfig.tokens.colors.onSurface,
            fontWeight: gluestackUIConfig.tokens.fontWeights.normal,
            lineHeight: gluestackUIConfig.tokens.lineHeights.md,
            fontSize: FONT_SIZE,
            ...fontFamilySettings,
          },
          u: fontFamilySettings,
        },
        messageUser: {
          fontSize: 13,
          display: 'none',
          ...fontFamilySettings,
        },
        metaContainer: {
          alignContent: 'center',
        },
        metaText: {
          padding: 2,
          ...fontFamilySettings,
        },
        textContainer: {
          maxWidth: undefined,
          paddingHorizontal: 0,
        },
      },
      file: {
        fileSize: fontFamilySettings,
        title: fontFamilySettings,
      },
      gallery: {
        moreImagesText: fontFamilySettings,
        image: { objectFit: 'contain' },
      },
      giphy: {
        cancel: fontFamilySettings,
        giphyHeaderText: fontFamilySettings,
        giphyHeaderTitle: fontFamilySettings,
        send: fontFamilySettings,
        shuffle: fontFamilySettings,
        title: fontFamilySettings,
      },
      pinnedHeader: {
        label: fontFamilySettings,
      },
      replies: {
        messageRepliesText: {
          ...fontFamilySettings,
          color: gluestackUIConfig.tokens.colors.primary,
          fontWeight: gluestackUIConfig.tokens.fontWeights.bold,
        },
      },
      status: {
        readByCount: { color: '#F28E18', fontWeight: '500', ...fontFamilySettings },
      },
    },
    overlay: {
      container: {
        marginTop: 80,
      },
      messageActions: {
        title: fontFamilySettings,
        actionContainer: {
          paddingVertical: 5,
          paddingHorizontal: 10,
        },
      },
      reactions: {
        avatarName: fontFamilySettings,
        title: fontFamilySettings,
      },
    },
    reply: {
      container: {
        alignItems: 'flex-start',
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: 4,
      },
      messageContainer: {
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
        borderTopLeftRadius: 0,
      },
      markdownStyles: {
        text: {},
      },
    },
    thread: {
      newThread: {
        text: fontFamilySettings,
      },
    },
    typingIndicator: {
      text: fontFamilySettings,
    },
  };
};

export const myMessageTheme: DeepPartial<Theme> = {
  messageSimple: {
    content: {
      containerInner: {
        backgroundColor: 'transparent',
        borderTopRightRadius: gluestackUIConfig.tokens.space.none,
        borderBottomLeftRadius: gluestackUIConfig.tokens.space.none,
        borderBottomRightRadius: gluestackUIConfig.tokens.space.none,
        borderWidth: 0,
        padding: 0,
        maxWidth: undefined,
        flexDirection: 'column-reverse',
      },
      markdown: {
        autolink: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        blockQuoteText: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        br: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        codeBlock: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        del: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        em: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        heading: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        heading1: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        heading2: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        heading3: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        heading4: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        heading5: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        heading6: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        inlineCode: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        listItemBullet: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        listItemNumber: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        listItemText: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        mailTo: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        mentions: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        newline: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        noMargin: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        paragraph: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        paragraphCenter: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        strong: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        tableHeaderCell: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontFamily: FONT_FAMILY,
        },
        text: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
          fontWeight: gluestackUIConfig.tokens.fontWeights.normal,
          lineHeight: gluestackUIConfig.tokens.lineHeights.md,
          fontSize: gluestackUIConfig.tokens.fontSizes.md,
          fontFamily: FONT_FAMILY,
        },
        u: {
          color: gluestackUIConfig.tokens.colors.onPrimary,
        },
      },
      messageUser: {
        display: 'none',
      },
      textContainer: {
        backgroundColor: 'transparent',
        paddingHorizontal: gluestackUIConfig.tokens.space.none,
        maxWidth: undefined,
      },
    },
  },
  reply: {
    messageContainer: {
      backgroundColor: 'transparent',
    },
  },
};
