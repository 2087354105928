import { useDidMount } from '@better-typed/react-lifecycle-hooks';
import { useContext, useState } from 'react';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { ChatContext } from '~/lib/StreamChatReact';

export const useUnread = () => {
  const [unreadRoom, setUnreadRoom] = useState(0);
  const [unreadDM, setUnreadDM] = useState(0);
  const { chatUserId } = useStreamChatContext();
  const chatContext = useContext(ChatContext);
  const chatClient = chatContext?.client;

  let isMounted = true;

  const fetchRoomUnreadCounts = async () => {
    const roomChannels = await chatClient?.queryChannels(
      // @ts-expect-error
      { members: { $in: [chatUserId] }, has_unread: true, isDM: false },
      {},
      { limit: 1 }
    );
    if (isMounted) {
      setUnreadRoom(roomChannels?.length || 0);
    }
  };

  const fetchDMUnreadCounts = async () => {
    const dmChannels = await chatClient?.queryChannels(
      // @ts-expect-error
      { members: { $in: [chatUserId] }, has_unread: true, isDM: true },
      {},
      { limit: 1 }
    );
    if (isMounted) {
      setUnreadDM(dmChannels?.length || 0);
    }
  };

  useDidMount(() => {
    fetchDMUnreadCounts();
    fetchRoomUnreadCounts();
    const listener = chatClient?.on(async (event) => {
      if (event.type === 'notification.mark_read' || event.type === 'notification.mark_unread') {
        if (event.channel?.isDM) {
          fetchDMUnreadCounts();
        } else {
          fetchRoomUnreadCounts();
        }
      }
      if (event.type === 'message.new') {
        if ((event.channel_custom as any)?.isDM) {
          if (unreadDM === 0) fetchDMUnreadCounts();
        } else {
          if (unreadRoom === 0) fetchRoomUnreadCounts();
        }
      }
    });

    return () => {
      isMounted = false;
      listener?.unsubscribe();
    };
  });

  return { unreadRoom, unreadDM };
};
