import { baseApi as api } from '../uMotionBaseQuery';
const addTagTypes = ['CowEvent', 'Cow'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      postCowEvent: build.mutation<PostCowEventApiResponse, PostCowEventApiArg>({
        query: (queryArg) => ({ url: `/cow_event`, method: 'POST', body: queryArg.cowEvent }),
        invalidatesTags: ['CowEvent'],
      }),
      getCowEventById: build.query<GetCowEventByIdApiResponse, GetCowEventByIdApiArg>({
        query: (queryArg) => ({ url: `/cow_event/${queryArg.id}` }),
        providesTags: ['CowEvent'],
      }),
      putCowEventById: build.mutation<PutCowEventByIdApiResponse, PutCowEventByIdApiArg>({
        query: (queryArg) => ({ url: `/cow_event/${queryArg.id}`, method: 'PUT', body: queryArg.cowEvent }),
        invalidatesTags: ['CowEvent'],
      }),
      deleteCowEventById: build.mutation<DeleteCowEventByIdApiResponse, DeleteCowEventByIdApiArg>({
        query: (queryArg) => ({ url: `/cow_event/${queryArg.id}`, method: 'DELETE' }),
        invalidatesTags: ['CowEvent'],
      }),
      getCowEventCowByCowId: build.query<GetCowEventCowByCowIdApiResponse, GetCowEventCowByCowIdApiArg>({
        query: (queryArg) => ({ url: `/cow_event/cow/${queryArg.cowId}` }),
        providesTags: ['CowEvent'],
      }),
      getCowEventCowByCowIdAndEventType: build.query<
        GetCowEventCowByCowIdAndEventTypeApiResponse,
        GetCowEventCowByCowIdAndEventTypeApiArg
      >({
        query: (queryArg) => ({ url: `/cow_event/cow/${queryArg.cowId}/${queryArg.eventType}` }),
        providesTags: ['CowEvent'],
      }),
      postCowEventBulk: build.mutation<PostCowEventBulkApiResponse, PostCowEventBulkApiArg>({
        query: (queryArg) => ({ url: `/cow_event/bulk`, method: 'POST', body: queryArg.cowEventBulk }),
        invalidatesTags: ['Cow'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as cowEventAutoGeneratedApi };
export type PostCowEventApiResponse = /** status 200 成功 */ {
  id?: number;
};
export type PostCowEventApiArg = {
  cowEvent: CowEvent;
};
export type GetCowEventByIdApiResponse = /** status 200 指定したIDのcow_eventのレコードが返される */ CowEvent;
export type GetCowEventByIdApiArg = {
  id: number;
};
export type PutCowEventByIdApiResponse = unknown;
export type PutCowEventByIdApiArg = {
  id: number;
  cowEvent: CowEvent;
};
export type DeleteCowEventByIdApiResponse = unknown;
export type DeleteCowEventByIdApiArg = {
  id: number;
};
export type GetCowEventCowByCowIdApiResponse =
  /** status 200 指定した cow_id のcow_eventのレコードの配列が返される */ CowEvent[];
export type GetCowEventCowByCowIdApiArg = {
  cowId: number;
};
export type GetCowEventCowByCowIdAndEventTypeApiResponse =
  /** status 200 指定した cow_id、evnent_type のcow_eventのレコードの配列が返される */ CowEvent[];
export type GetCowEventCowByCowIdAndEventTypeApiArg = {
  cowId: number;
  eventType: string;
};
export type PostCowEventBulkApiResponse = unknown;
export type PostCowEventBulkApiArg = {
  cowEventBulk: CowEventBulk;
};
export type CowEvent = {
  id?: number;
  cowId: number;
  occurredAt: number;
  eventType: string;
  eventName: string;
  entryStaffName?: string;
  lastEditStaffName?: string;
  workerName?: string;
  comment?: string;
  taioTitle?: string;
  moveToCowGroupId?: number;
  beforeCowGroupId?: number;
  moveToPen?: string;
  beforePen?: string;
  targetShipmentKind?: string;
  tanetsukeMethod?: string;
  targetBreedingDate?: number;
  masterSpermId?: number;
  bullCowId?: number;
  donorCowUid?: string;
  donorBreed?: string;
  donorRegistrationNo?: string;
  donorSpermNo?: string;
  donorName?: string;
  donorBreedingValue?: string;
  fatherRegistrationNoOfDonor?: string;
  fatherNameOfDonor?: string;
  motherRegistrationNoOfDonor?: string;
  motherNameOfDonor?: string;
  motherBreedOfDonor?: string;
  maternalGrandfatherRegistrationNoOfDonor?: string;
  maternalGrandfatherSpermNoOfDonor?: string;
  maternalGrandfatherNameOfDonor?: string;
  masterEmbryoId?: string;
  embryos?: {
    rank?: string;
    count?: number;
  }[];
  embryoRecoveryUnfertilized?: number;
  embryoMasterRegistered?: boolean;
  bredForEmbryoRecovery?: boolean;
  ninshinkanteiResult?: string;
  judgePregnantTiming?: string;
  breedingCowEventId?: number;
  selectedDryPeriod?: string;
  calfs?: {
    cowId?: number;
    cow_no?: string;
    cow_uid?: string;
    calf_no?: string;
    breed?: string;
    gender?: string;
    registered?: boolean;
    birth_state?: string;
    birth_result?: string;
    weight_on_birth?: number;
  }[];
  childNumber?: number;
  calvingDifficulty?: string;
  freshCheckResult?: string;
  nextCowState?: string;
  afterCowState?: string;
  breedingExclusionReason?: string;
  masterHormoneProgramId?: number;
  hormoneProgramName?: string;
  hormoneName?: string;
  hormoneCode?: string;
  plannedBredAt?: any;
  plannedBredMethod?: string;
  occurredDiseaseDate?: number;
  treatmentDiseaseDate?: number;
  diagnosisResultDetail?: string;
  masterMedicineIds?: string;
  medicineMethods?: string;
  medicineCapacities?: string;
  maxMilkMedicineName?: string;
  maxBeefMedicineName?: string;
  endDateOfMilkWashoutPeriod?: number;
  endDateOfBeefWashoutPeriod?: number;
  bodyTemperature?: string;
  vaccineTiming?: string;
  mastitisBacteriaOfBreastFl?: string;
  mastitisBacteriaOfBreastFr?: string;
  mastitisBacteriaOfBreastBl?: string;
  mastitisBacteriaOfBreastBr?: string;
  mastitisDiagnosisResultOfBreastFl?: string;
  mastitisDiagnosisResultOfBreastFr?: string;
  mastitisDiagnosisResultOfBreastBl?: string;
  mastitisDiagnosisResultOfBreastBr?: string;
  stopMilkingOfBreastFl?: boolean;
  stopMilkingOfBreastFr?: boolean;
  stopMilkingOfBreastBl?: boolean;
  stopMilkingOfBreastBr?: boolean;
  mastitisScore?: number;
  mastitisCowGroupId?: number;
  difficultiesOvaryStill?: string;
  difficultiesOvaryCystic?: string;
  difficultiesRemnant?: string;
  difficultiesMetritis?: string;
  difficultiesPyometra?: string;
  difficultiesKetosis?: string;
  difficultiesFattyLiver?: string;
  difficultiesAbomasumDisplacement?: string;
  difficultiesHypocalcium?: string;
  difficultiesAcidosis?: string;
  difficultiesDowner?: string;
  difficultiesStagnantFood?: string;
  difficultiesHipDislocation?: string;
  difficultiesTeatInjury?: string;
  difficultiesOther?: string;
  difficultiesPneumonia?: string;
  difficultiesDiarrhea?: string;
  difficultiesSkinDisease?: string;
  difficultiesTympanites?: string;
  diagnosisResultOfOvaryL?: string;
  diagnosisResultOfOvaryR?: string;
  nextStartMilkingFlg?: boolean;
  examinationTreatmentKind?: string;
  uterusDiagnosisResult?: string;
  eyeCondition?: string;
  earCondition?: string;
  muzzleCondition?: string;
  hairCondition?: string;
  hipCondition?: string;
  bodyCondition?: string;
  fecesCondition?: string;
  urineCondition?: string;
  otherReproductionWork?: string;
  difficultiesPlacenta?: string;
  difficultiesUrolithiasis?: string;
  difficultiesVitaminA?: string;
  clawDiseaseName1?: string;
  clawDiseaseName2?: string;
  clawDiseaseName3?: string;
  clawDiseaseName4?: string;
  locomotionScore?: number;
  lameAffectedLimb1?: string;
  lameAffectedLimb2?: string;
  lameAffectedLimb3?: string;
  lameAffectedLimb4?: string;
  lameAffectedPart1?: string;
  lameAffectedPart2?: string;
  lameAffectedPart3?: string;
  lameAffectedPart4?: string;
  lameClawZones1?: string;
  lameClawZones2?: string;
  lameClawZones3?: string;
  lameClawZones4?: string;
  lameCondition1?: string;
  lameCondition2?: string;
  lameCondition3?: string;
  lameCondition4?: string;
  otherDiseaseName?: string;
  otherDiseaseNames?: {
    id?: number;
    name?: string;
  }[];
  deathReason?: string;
  dehornMethod?: string;
  castratMethod?: string;
  weight?: number;
  dg?: number;
  breedingDays?: number;
  bcs?: number;
  shipmentNo?: string;
  grade?: string;
  yieldGrade?: string;
  meetGrade?: string;
  beforeSlaughterWeight?: number;
  dressedCarcassWeightOfL?: number;
  dressedCarcassWeightOfR?: number;
  loinArea?: number;
  ribsThickness?: number;
  subcutaneousFat?: number;
  yieldBaseValue?: number;
  bmsNo?: string;
  marblingGrade?: string;
  bcsNo?: string;
  gloss?: string;
  bcsAndGlossGrade?: string;
  tight?: string;
  texture?: string;
  tightAndTextureGrade?: string;
  bfsNo?: string;
  fatLuster?: string;
  bfsAndFatLusterGrade?: string;
  otherFaultCorrection?: string;
  dressedCarcassUnitPrice?: number;
  dressedCarcassSalesPrice?: number;
  visceralDestruction?: string;
  buyer?: string;
  salesPrice?: any;
  deadProcessCost?: string;
  carcassDefects?: {
    value?: number;
    parts?: {
      value?: string;
    }[];
  }[];
  inseminationCode?: string;
  slaughterNo?: string;
  totalDressedCarcassWeight?: number;
  producingArea?: string;
  producingFarmName?: string;
  expelledReason?: string;
  imported?: boolean;
  inputPath?: string;
  bqNotes?: string;
  bqInputTime?: number;
};
export type CowEventBulk = {
  bqInputTime: number;
  bqNotes: string;
  _json: {
    eventType?: string;
    eventName?: string;
    entryStaffName?: string;
    lastEditStaffName?: string;
    cowId?: number;
    comment?: string;
    breedingExclusionReason?: string;
  }[];
};
export const {
  usePostCowEventMutation,
  useGetCowEventByIdQuery,
  usePutCowEventByIdMutation,
  useDeleteCowEventByIdMutation,
  useGetCowEventCowByCowIdQuery,
  useGetCowEventCowByCowIdAndEventTypeQuery,
  usePostCowEventBulkMutation,
} = injectedRtkApi;
