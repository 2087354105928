import { Box, Text, VStack, useTheme } from '@gluestack-ui/themed-native-base';
import React, { useMemo, useState } from 'react';
import { Dimensions, Platform } from 'react-native';

import SimpleMarkdown, { inlineRegex, blockRegex } from 'simple-markdown';
import { TEXT_WIDTH, getChatTheme, myMessageTheme } from '~/chatTheme';

import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { useChumlyLink } from '~/hooks/useChumlyLink';
// @ts-expect-error TS(2305): Module '"~/lib/StreamChatReact"' has no exported m... Remove this comment to see the full error message
import type { DefaultStreamChatGenerics, RenderTextParams } from '~/lib/StreamChatReact';
import { renderText, useChatContext } from '~/lib/StreamChatReact';
import { overlayReactionSelector } from '~/slices/overlayReactionSlice';
import { settingsSelector } from '~/slices/settingsSlice';
import { useAppSelector } from '~/store';
import { markdownRulesForKanasapo } from '~/utils/renderTextWithKanasapo';

import { EmojiPickerComponent } from './EmojiPicker';
import { Previews } from './Previews';
import type { DefaultInOutRule, DefaultRules } from 'simple-markdown';
import type { Attachment, ReactionResponse } from 'stream-chat';

const LINK_INSIDE = '(?:\\[[^\\]]*\\]|[^\\[\\]]|\\](?=[^\\[]*\\]))*';
const LINK_HREF_AND_TITLE = '\\s*<?((?:\\([^)]*\\)|[^\\s\\\\]|\\\\.)*?)>?(?:\\s+[\'"]([\\s\\S]*?)[\'"])?\\s*';

const UNESCAPE_URL_R = /\\([^0-9A-Za-z\s])/g;

const unescapeUrl = (rawUrlString: string | undefined) => {
  return typeof rawUrlString === 'string' ? rawUrlString.replace(UNESCAPE_URL_R, '$1') : '';
};

// modified: https://github.com/ariabuckles/simple-markdown/blob/7fb8bb5943ee4e561fec17c2e271a327f4e86d64/src/index.js#L1501
// modified: https://github.com/GetStream/stream-chat-react-native/blob/d0169b97c41d4f5dadc439585a63fa4109121fcb/package/src/components/Message/MessageSimple/utils/renderText.tsx#L146
const getCustomMarkdownRules = (
  isMyMessage: boolean,
  onPress: (url: string) => void,
  getRules?: () => {
    colors: ReturnType<typeof useTheme>['colors'];
    markdownRules: DefaultRules;
    markdownStyles: any;
  }
): {
  blockQuote: Partial<DefaultInOutRule>;
  codeBlock: Partial<DefaultInOutRule>;
  inlineCode: Partial<DefaultInOutRule>;
  link: Partial<DefaultInOutRule>;
  url: Partial<DefaultInOutRule>;
  list: Partial<DefaultInOutRule>;
} => {
  return {
    list: {
      match: blockRegex(/^(\s*[\*\-\+]\s+.*|\s*\d+\.\s+.*)/),
      parse(capture, parse, state) {
        const items: { level: number; item: string }[] = [];
        const lines = capture[0].split('\n').filter((line) => line.trim() !== '');
        let currentLevel = -1;

        lines.forEach((line) => {
          const orderedMatch = line.match(/^(\s*\d+\.)\s+(.*)/);
          const unorderedMatch = line.match(/^(\s*[\*\-\+])\s+(.*)/);

          const createLabel = (level: number, index: number) => {
            if (level === 0) {
              return `${index}.`;
            } else if (level === 4) {
              return `${String.fromCharCode(97 + index - 1)}.`; // a., b., c., ...
            } else if (level === 8) {
              return `${['i', 'ii', 'iii', 'iv', 'v'][index - (1 % 5)]}.`; // i., ii., iii., iv., v.
            } else {
              return `${index}.`;
            }
          };

          if (orderedMatch) {
            const level = orderedMatch[1].length - orderedMatch[1].trim().length;
            const item = orderedMatch[2].trim();
            const index = Number(orderedMatch[1].trim().replace(/\./g, ''));
            const label = createLabel(level, index);

            if (level > currentLevel) {
              currentLevel = level;
              items.push({ level, item: `${label} ${item}` });
            } else if (level === currentLevel) {
              items[items.length - 1].item += `\n${item}`;
            } else {
              while (currentLevel > level) {
                currentLevel--;
                items.pop();
              }
              items[items.length - 1].item += `\n${item}`;
            }
          } else if (unorderedMatch) {
            const level = unorderedMatch[1].length - unorderedMatch[1].trim().length;
            const item = unorderedMatch[2].trim();
            const label = level === 0 ? '\u2022' : level === 4 ? '\u2218' : level === 8 ? '\u25AB' : '-';

            if (level > currentLevel) {
              currentLevel = level;
              items.push({ level, item: `${label} ${item}` });
            } else if (level === currentLevel) {
              items[items.length - 1].item += `\n${item}`;
            } else {
              while (currentLevel > level) {
                currentLevel--;
                items.pop();
              }
              items[items.length - 1].item += `\n${item}`;
            }
          }
        });

        return { items };
      },
      react(node, output, state) {
        return (
          <Box key={state.key}>
            {node.items.map((listItem: { level: number; item: any }, index: any) => (
              <Text
                key={index}
                color={isMyMessage ? 'onPrimary' : 'onSurface'}
                fontSize="md"
                ml={listItem.level === 0 ? gluestackUIConfig.tokens.space[0] : listItem.level === 4 ? 'md' : '2xl'}
              >
                {listItem.item}
              </Text>
            ))}
          </Box>
        );
      },
    },
    blockQuote: {
      match: blockRegex(/^( *\\>[^\n]+(\n[^\n]+)*\n*)+\n{2,}/),
      parse(capture, parse, state) {
        const content = capture[0].replace(/^ *\\> ?/gm, '');
        return {
          content: parse(content, state),
        };
      },
      react(node, output, state) {
        return (
          <Box
            my={2}
            key={state.key}
            borderLeftWidth={gluestackUIConfig.tokens.borderWidths.extraThick}
            borderColor={isMyMessage ? 'onPrimary' : 'onSurfaceHighest'}
            pl="sm"
          >
            <Text fontSize="md" color={isMyMessage ? 'onPrimary' : 'onSurface'}>
              {output(node.content, { ...state })}
            </Text>
          </Box>
        );
      },
    },
    codeBlock: {
      react(node, output, state) {
        const regex = /<ruby>.*?<\/ruby>/;
        const unescapeContent = unescapeUrl(node.content);
        const isRubyIncluded = regex.test(unescapeContent);

        let codeBlockText: React.ReactNode;
        if (isRubyIncluded) {
          const rules = getRules?.();
          const customRulesForLink = {
            ...rules,
            markdownRules: {
              ...rules?.markdownRules,
              link: {
                ...SimpleMarkdown.defaultRules.link,
                parse(capture: any) {
                  const content = capture[1];
                  return {
                    content,
                  };
                },
                react(node: any) {
                  return <Text>{unescapeUrl(node.content)}</Text>;
                },
              },
            },
          };

          codeBlockText = renderText({
            ...customRulesForLink,
            message: {
              text: unescapeContent,
            },
          } as any);
        } else {
          const linkRegex = /\[([^\]]+)\]\([^\)]+\)/g;
          const nodeContentWithoutLink = node.content.replace(linkRegex, '$1');
          codeBlockText = (
            <Text fontSize="md" color={isMyMessage ? 'onPrimary' : 'onSurface'}>
              {nodeContentWithoutLink}
            </Text>
          );
        }

        return (
          <Box
            py="3xs"
            px="2xs"
            my="sm"
            borderRadius={gluestackUIConfig.tokens.borderRadius.small}
            borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor={isMyMessage ? 'onPrimary' : 'outline'}
            backgroundColor={isMyMessage ? 'primaryContainer' : 'surfaceHigh'}
            key={state.key}
          >
            {codeBlockText}
          </Box>
        );
      },
    },
    inlineCode: {
      react(node, _output, _state) {
        // do not linkify
        const content = node.content.flatMap(
          (val: { type: string; content: string | { type: string; content: string } }) => {
            if (typeof val === 'object' && val.type === 'link') {
              return val.content;
            }
            return val;
          }
        ) as { type: string; content: string }[];
        const inlineCodeContent = content
          .map((item) => item.content)
          .join('')
          .trim();
        const windowDimensions = Dimensions.get('window');
        return (
          <Box
            py="3xs"
            px="2xs"
            borderRadius={gluestackUIConfig.tokens.borderRadius.small}
            borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor={isMyMessage ? 'onPrimary' : 'outline'}
            backgroundColor={isMyMessage ? 'primaryContainer' : 'surfaceHigh'}
            maxWidth={windowDimensions.width - 132}
          >
            <Text fontWeight="bold" fontSize="md" color={isMyMessage ? 'onPrimary' : 'primary'}>
              {unescapeUrl(inlineCodeContent)}
            </Text>
          </Box>
        );
      },
    },
    link: {
      match: inlineRegex(new RegExp('^\\[(' + LINK_INSIDE + ')\\]\\(' + LINK_HREF_AND_TITLE + '\\)')),
      react(node, output, state) {
        const url = node.target;

        return (
          <Text
            key={state.key}
            onPress={() => onPress(url)}
            fontSize="md"
            color={isMyMessage ? 'onPrimary' : 'onSurface'}
            textDecorationLine="underline"
          >
            {output(node.content, { ...state, withinLink: true })}
          </Text>
        );
      },
    },
    url: {
      match: inlineRegex(/^(https?:\/\/[^\s\]<]+[^<.,:;"')\\\]\s])/),
      parse(capture, parse, state) {
        return {
          type: 'link',
          content: [
            {
              type: 'text',
              content: unescapeUrl(capture[1]),
            },
          ],
          target: unescapeUrl(capture[1]),
          title: undefined,
        };
      },
      react(node, output, state) {
        const url = node.target;

        return (
          <Text
            key={state.key}
            onPress={() => onPress(url)}
            fontSize="md"
            color={isMyMessage ? 'onPrimary' : 'onSurface'}
            textDecorationLine="underline"
          >
            {output(node.content, { ...state, withinLink: true })}
          </Text>
        );
      },
    },
  };
};

export const CustomMessageText = (props: RenderTextParams<DefaultStreamChatGenerics> & { isSearch?: boolean }) => {
  const { colors } = useTheme();
  const { message, messageOverlay, markdownRules, markdownStyles } = props;
  const settings = useAppSelector(settingsSelector);
  const { targetMessageId, showEmojiModal } = useAppSelector(overlayReactionSelector);
  const [allReactionList, setAllReactionList] = useState<ReactionResponse[]>([]);
  const { chatUserId } = useStreamChatContext();

  const isMyMessage = message?.user?.id === chatUserId;

  const { channel } = useChatContext();

  const showEmojiPicker = useMemo(() => {
    return targetMessageId === message?.id && showEmojiModal;
  }, [targetMessageId, message?.id, showEmojiModal]);

  const onPress = useChumlyLink();

  const customMarkdownRules = getCustomMarkdownRules(isMyMessage, onPress, () => ({
    colors,
    markdownRules: {
      ...markdownRules,
      ...getCustomMarkdownRules(isMyMessage, onPress),
      ...(settings.enableKanasapo ? markdownRulesForKanasapo : {}),
    },
    markdownStyles: {
      ...markdownStyles,
      ...getChatTheme(settings.enableUDFont).messageSimple?.content?.markdown,
    },
  }));

  React.useEffect(() => {
    const getReactionsList = async () => {
      if (message.latest_reactions && message.latest_reactions.length < 10) {
        setAllReactionList(message.latest_reactions as ReactionResponse[]);
        return;
      }

      if (!message.id) {
        setAllReactionList([]);
        return;
      }

      try {
        const response = await channel?.getReactions(message.id, { limit: 300 });
        if (response) {
          setAllReactionList([...response.reactions]);
        }
      } catch (e) {
        console.error(e);
      }
    };

    getReactionsList();
  }, [channel, message.latest_reactions]);

  const MemoizedCustomMessageTextComponent = useMemo(() => {
    const copymessage = { ...message };
    if (messageOverlay && copymessage.text && copymessage.text.length > 50)
      copymessage.text = copymessage.text.substring(0, 49) + '...';

    const isMentionAtStart = (text: string): boolean => {
      const mentionRegex = /^\*\*@\S+\*\*/;
      return mentionRegex.test(text);
    };

    const getRubyDict = () => {
      const rubyDict: { [key: string]: string } = {};
      const rubyRegex = /<ruby>(.*?)《(.*?)》<\/ruby>/g;

      let match;
      while ((match = rubyRegex.exec(message?.ruby || ''))) {
        rubyDict[match[1]] = match[2];
      }
      return rubyDict;
    };

    const getTextWithRuby = (text: string) => {
      const rubyDict = getRubyDict();

      if (isMentionAtStart(text)) {
        text = text.replace(/^\*\*@(\S+)\*\*/, '__@$1__');
      }

      if (Object.keys(rubyDict).length === 0) return text;

      const rubyRegex = new RegExp(Object.keys(rubyDict).join('|'), 'g');
      return text.replace(rubyRegex, (matched) => `<ruby>${matched}《${rubyDict[matched]}》</ruby>`);
    };

    if (!message) {
      return null;
    }

    const formatAttachments = (attachments: Attachment[]) => {
      return attachments.map((attachment) => {
        if (attachment?.og_scrape_url) {
          attachment.og_scrape_url = unescapeUrl(attachment.og_scrape_url);
        }
        return attachment;
      });
    };

    const CustomMessage = () => {
      return (
        <VStack dataSet={{ classname: 'message_parent' }}>
          <Box textAlign="left" fontWeight="regular" color="onSurface" fontSize="md">
            {message.type !== 'deleted' ? (
              Platform.OS === 'web' ? (
                renderText(messageOverlay ? copymessage?.text : message?.text)
              ) : (
                renderText(
                  message.text && props.isSearch
                    ? {
                        ...props,
                        colors,
                        message: messageOverlay
                          ? copymessage
                          : settings.enableKanasapo && message.ruby && message.ruby !== ''
                            ? {
                                ...message,
                                text: getTextWithRuby(
                                  message.text.length > 80 ? message.text.substring(0, 79) + '...' : message.text
                                ),
                                attachments: message.attachments && formatAttachments(message.attachments),
                              }
                            : {
                                ...message,
                                attachments: message.attachments && formatAttachments(message.attachments),
                                text: isMentionAtStart(message.text)
                                  ? message.text.length > 80
                                    ? message.text.replace(/^\*\*@(\S+)\*\*/, '__@$1__').substring(0, 79) + '...'
                                    : message.text.replace(/^\*\*@(\S+)\*\*/, '__@$1__')
                                  : message.text.length > 80
                                    ? message.text.substring(0, 79) + '...'
                                    : message.text,
                              },
                        markdownRules: {
                          ...markdownRules,
                          ...customMarkdownRules,
                          ...(settings.enableKanasapo ? markdownRulesForKanasapo : {}),
                        },
                      }
                    : {
                        ...props,
                        colors,
                        message: messageOverlay
                          ? copymessage
                          : settings.enableKanasapo && message.ruby && message.ruby !== ''
                            ? {
                                ...message,
                                text: getTextWithRuby(message.text || ''),
                                attachments: message.attachments && formatAttachments(message.attachments),
                              }
                            : {
                                ...message,
                                attachments: message.attachments && formatAttachments(message.attachments),
                                text: isMentionAtStart(message.text)
                                  ? message.text.replace(/^\*\*@(\S+)\*\*/, '__@$1__')
                                  : message.text,
                              },
                        markdownRules: {
                          ...markdownRules,
                          ...customMarkdownRules,
                          ...(settings.enableKanasapo ? markdownRulesForKanasapo : {}),
                        },
                        markdownStyles: {
                          ...markdownStyles,
                          ...getChatTheme(settings.enableUDFont).messageSimple?.content?.markdown,

                          ...(chatUserId === message.user?.id ? myMessageTheme.messageSimple?.content?.markdown : {}),

                          list: {
                            maxWidth: TEXT_WIDTH - 24,
                          },
                          paragraph: {
                            alignItems: 'flex-end',
                            color: gluestackUIConfig.tokens.colors.onPrimary,
                          },
                          strong: {
                            textAlign: 'left',
                            color: gluestackUIConfig.tokens.colors.onPrimary,
                          },
                          text: {
                            textAlign: 'left',
                            color: isMyMessage
                              ? gluestackUIConfig.tokens.colors.onPrimary
                              : gluestackUIConfig.tokens.colors.onSurface,
                          },
                        },
                      }
                )
              )
            ) : (
              <Text padding={1} fontSize="sm" color="onSurfaceHigh">
                メッセージが削除されました
              </Text>
            )}
          </Box>
          {messageOverlay ? null : <Previews message={message} />}
        </VStack>
      );
    };

    return (
      <>
        <CustomMessage />
        {/* @ts-expect-error TS(2322): Type '{ messageId: any; latestReactions: any; }' i... Remove this comment to see the full error message */}
        {showEmojiPicker && <EmojiPickerComponent messageId={message.id} latestReactions={allReactionList} />}
      </>
    );
  }, [message, targetMessageId, showEmojiModal, settings.enableUDFont, settings.enableKanasapo]);

  return MemoizedCustomMessageTextComponent;
};
